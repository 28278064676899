import { createSelector } from '@ngrx/store'
import { selectJwtUser } from 'common/store/jwt/jwt.selectors'
import { UpdatePersonalDataInCartRequest } from '../../models/update-personal-data-in-cart-request'
import { minionName } from '../../pipes/minion/minion-name.pipe'

export const selectUserData = createSelector(selectJwtUser, info =>
  info?.role === 'clientPartner' ? info?.data?.userInfo.main_user_data : undefined,
)

export const selectUserBonusSystemData = createSelector(selectJwtUser, info =>
  info?.role === 'clientPartner' ? info?.data?.userInfo.bonus_system_data : undefined,
)

export const selectUserId = createSelector(
  selectJwtUser,
  user =>
    (user?.role === 'clientPartner' && user?.data?.userInfo.main_user_data?.id) ||
    (user?.role === 'admin' && user?.data?.id) ||
    (user?.role === 'support' && user?.data?.id) ||
    undefined,
)

export const selectUserVisibleId = createSelector(selectUserData, data => data?.visible_id)

export const selectUserIsCic = createSelector(selectUserData, data => data?.is_cic)

export const selectUserRole = createSelector(
  selectJwtUser,
  user =>
    (user?.role === 'clientPartner' && user?.data?.userInfo.main_user_data?.role) ||
    (user?.role === 'admin' && user?.data?.role) ||
    (user?.role === 'support' && user?.data?.role) ||
    undefined,
)

export const selectUserName = createSelector(
  selectUserData, data => data?.name || ""
)

export const selectUserPersonalData = createSelector(
  selectUserData,
  (data): UpdatePersonalDataInCartRequest => ({
    name: data && minionName(data),
    phone: data?.phone,
    email: data?.email,
    // iin нет в профиле
  }),
)

export const selectIsAuthorized = createSelector(selectJwtUser, Boolean)

export const selectUserAccounts = createSelector(selectJwtUser, info =>
	info?.role === 'clientPartner' ? info?.data?.userInfo.accounts : undefined,
)
