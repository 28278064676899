import { Injectable } from '@angular/core'
import { FundsTransfer } from 'common/models/funds-transfer'
import { FundsTransferRequest } from 'common/models/funds-transfer-request'
import { FundsTransferType } from 'common/models/funds-transfer-type'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { RequestService } from 'common/services/request.service'

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class TransferBonusService {
	constructor(private requestService: RequestService) {
	}

	url = 'api/account/funds_transfer/send'
	urlInfo = 'api/account/funds_transfer/withdrawal_info'

	send(bonusAmount: number, to: number, balanceType: FundsTransferRequest.BalanceTypeEnum, iso_code: string, comment: string) {
		return this.requestService.post<FundsTransferRequest, FundsTransfer>(this.url, {
			transfer_type: 'FundsTransferTypeTransfer',
			amount: bonusAmount,
			transfer_to_visible_id: to,
			balance_type: balanceType,
			comment: comment,
			iso_code: iso_code,
			iso_code_to: iso_code
		})
	}

	getInfo() {
		return this.requestService.get<FundsTransferWithdrawalResponse>(this.urlInfo, {})
	}

	withdraw(bonusAmount: number, iso_code: string, balanceType: FundsTransferRequest.BalanceTypeEnum = 'BalanceTypeBonus') {
		return this.requestService.post<FundsTransferRequest, FundsTransfer>(this.url, {
			transfer_type: FundsTransferType.FundsTransferTypeWithdrawal,
			amount: bonusAmount,
			balance_type: balanceType,
			iso_code: iso_code,
			iso_code_to: iso_code
		})
	}

	getMinionById(visibleId: number) {
		return this.requestService.post<{ minion_visible_id: number }, MinionFromMyTeamResponse>(
			'/api/my_team/get_minion_v2',
			{
				minion_visible_id: visibleId
			}
		)
	}
}
