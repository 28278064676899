import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of, switchMap } from 'rxjs'
import { getMinionById, getMinionWithdrawInfo, sendBonus, withdrawBonus } from './transfer-bonus.actions'
import { TransferBonusService } from '../api/transfer-bonus.service'
import { loadUserInfo } from '../../../../store/auth/auth.actions'

@Injectable()
export class TransferBonusEffects {
	dialog: any
	constructor(private actions$: Actions, private transferBonusService: TransferBonusService) {}

	send$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sendBonus.start),
			mergeMap(({ bonusAmount, visibleId, balanceType, iso_code, comment }) =>
				this.transferBonusService.send(bonusAmount, visibleId, balanceType, iso_code, comment).pipe(
					map(transfer => sendBonus.success({ transfer })),
					catchError(error => of(sendBonus.error({ error: String(error) })))
				)
			)
		)
	)

	getMinionWithdrawInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getMinionWithdrawInfo.start),
			switchMap(() =>
				this.transferBonusService.getInfo().pipe(
					map(response => {
						return getMinionWithdrawInfo.success({ response })
					}),
					catchError(error => {
						return of(getMinionWithdrawInfo.error({ error: String(error) }))
					})
				)
			)
		)
	)

	getMinionById$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getMinionById.start),
			mergeMap(({ visibleId }) =>
				this.transferBonusService.getMinionById(visibleId).pipe(
					map(response => getMinionById.success({ response })),
					catchError(error => of(getMinionById.error({ error: String(error) })))
				)
			)
		)
	)

	withdraw$ = createEffect(() =>
		this.actions$.pipe(
			ofType(withdrawBonus.start),
			mergeMap(({ bonusAmount, iso_code }) =>
				this.transferBonusService.withdraw(bonusAmount, iso_code).pipe(
					map(transfer => withdrawBonus.success({ transfer })),
					catchError(error => of(withdrawBonus.error({ error: String(error) })))
				)
			)
		)
	)

	refreshUserDataOnTransferAndWithdraw$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sendBonus.success, withdrawBonus.success),
			mergeMap(() => of(loadUserInfo()))
		)
	)
}
